import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33a2c458"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container omni-mobile-help",
  id: "omni-help-content"
}
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.detail.title), 1),
    _createVNode(_component_Markdown, {
      source: _ctx.detail.content,
      html: ""
    }, null, 8, ["source"])
  ]))
}