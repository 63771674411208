
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import api from "@/assets/js/api";
import { Collapse, CollapseItem } from "vant";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    Markdown,
  },
  setup(props, ctx) {
    const route = useRoute();
    const key = route.params.key;

    const detail = ref({
      title: "",
      content: "",
    });
    const getDetail = async () => {
      detail.value = (await api.gethelpDetail({ key })).data;
    };
    getDetail();

    return { detail };
  },
});
