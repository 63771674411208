import axios from 'axios'
import store from '@/store/index.ts'
import storage from './localStorage'

axios.interceptors.request.use(config=>{
  // config.headers.packagePlatform = 'Web'
  if(sessionStorage.getItem('userToken')){
    // config.headers['X-App-Token'] = sessionStorage.getItem('userToken')
    config.headers['token'] = sessionStorage.getItem('userToken') 
  }
  
  if(storage.getLoginToken()){
    config.headers['token'] = storage.getLoginToken()
    config.headers['uid'] = storage.getUserInfo().user_id
  }
  if(sessionStorage.getItem('uid')) {
    config.headers['uid'] = sessionStorage.getItem('uid')
  }
  if(!config.url.includes('http')) {
    if(!config.url.includes('/api')) config.url = store.state.url + config.url
   
  }
  // const token = window.localStorage.getItem('loginToken')
  // if(token && !config.headers.Authorization) {
  //   config.headers.Authorization = token
  // }
  return config
}),
error=>{
  return Promise.error(error);
}

axios.interceptors.response.use(
  response => {
    if (response.status === 200 && ([10000, 1000, 0].includes(response.data.code))) {
      return Promise.resolve(response.data);
    } else if (response.status === 200) {
      return Promise.reject(response.data.msg)
    } else {
      return Promise.reject(response.data || response);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

// const http = async (url, method, params, config) =>{
//   try{
//     let res = '';
//     switch(method.tolocaleLowerCase){
//       case 'get': res = await axios.get(store.state.CMS.url+url, { params }, config)
//       break;
//       case 'post': res = await axios.post(store.state.CMS.url+url, params, config)
//       break;
//       case 'delete': res = await axios.get(store.state.CMS.url+url, { params })
//       break;
//       case 'put': res = await axios.get(store.state.CMS.url+url, params)
//       break;
//     }
//     if(res.code === 10000) {
//       return res.data
//     }
//   }catch(e){
//     console.error(e)
//   }
// }


export default axios