const config = {
    type: 'localStorage', // 本地存储类型 sessionStorage
    prefix: 'moblieh5_0.0.1', // 
    expire: 0, //过期时间 单位：秒
}

// 名称前自动添加前缀
const autoAddPrefix = (key) => {
    const prefix = config.prefix ? config.prefix + '_' : '';
    return  prefix + key;
}
const setStorage = (key, value, expire = 0) => {
    if (value === '' || value === null || value === undefined) {
        value = null;
    }
    if (isNaN(expire) || expire < 0) throw new Error("Expire must be a number");
 
    expire = (expire ? expire : config.expire) * 1000;
    let data = {
        value: value, // 存储值
        time: Date.now(), //存值时间戳
        expire: expire // 过期时间
    }  
    const encryptString = JSON.stringify(data);

    window[config.type].setItem(autoAddPrefix(key), encryptString);
}

// 移除已添加的前缀
const autoRemovePrefix = (key) => {
    const len = config.prefix ? config.prefix.length + 1  : '';
    return key.substr(len)
    // const prefix = config.prefix ? config.prefix + '_' : '';
    // return  prefix + key;
}
const removeStorage = (key) => {
    window[config.type].removeItem(autoAddPrefix(key));
}


export const getStorage = (key) => {
    key = autoAddPrefix(key);
    // key 不存在判断
    if (!window[config.type].getItem(key) || JSON.stringify(window[config.type].getItem(key)) === 'null') {
        return null;
    }
 
    // 获取
    const storage = JSON.parse(window[config.type].getItem(key));
    
    let nowTime = Date.now();
 
    // 过期删除
    if (storage.expire && config.expire * 6000 < (nowTime - storage.time)) {
        removeStorage(key);
        return null;
    } else {
        // 未过期期间被调用 则自动续期 进行保活
        setStorage(autoRemovePrefix(key), storage.value);
        let va = null
        try{
            va = JSON.parse(storage.value);
        }catch(e){
            va = storage.value
        }
        return va;
    }
}
// 使用原先的基本方法
const isOld = false






export default {
    getLoginToken: ()=>{
        if( isOld && window.localStorage.getItem('loginToken')) {
            return   window.localStorage.getItem('loginToken')
            } 
            return getStorage('loginToken')
    },
    getUserInfo: ()=>{
        if( isOld && window.localStorage.getItem('userInfo')){
            return   JSON.parse(window.localStorage.getItem('userInfo')) 
        }
        return getStorage('userInfo')
    },
    // 详情跳转结算页参数
    getOrderParams: ()=>{
        if( isOld && window.localStorage.getItem('orderParams')){
          return   JSON.parse(window.localStorage.getItem('orderParams')) 
        }
        return getStorage('orderParams')
    },
    // 跳转支付状态页的参数
    getTradeNo: ()=>{
        if( isOld && window.localStorage.getItem('tradeNo')){
            return   window.localStorage.getItem('tradeNo')
          }
          return getStorage('tradeNo')
    },
    // 缓存的订单信息，用来充当支付失败的结算页详情
    getOrderInfo: ()=>{
        if( isOld && window.localStorage.getItem('orderInfo')){
            return   JSON.parse(window.localStorage.getItem('orderInfo'))   
          }
          return getStorage('orderInfo')
    },
    // 缓存的订单信息，用来充当支付失败的结算页详情
    getGoodsParams: ()=>{
        if( isOld && window.localStorage.getItem('goodsParams')){
            return   JSON.parse(window.localStorage.getItem('goodsParams'))   
          }
          return getStorage('goodsParams')
    },
      // 用来判断用户是否从支付宝支付页面返回
      getPayDetailBack: ()=>{
        if( isOld && window.localStorage.getItem('payDetailBack')){
            return   Number(window.localStorage.getItem('payDetailBack'))
          }
          return getStorage('payDetailBack')
    },





    setOrderParams: (info)=>{
        if(isOld){
            window.localStorage.setItem('orderParams',JSON.stringify(info))
        } else {
            setStorage('orderParams',JSON.stringify(info))
        }
       
    },
    setUserInfo: (info)=>{
        if(isOld){
            window.localStorage.setItem('userInfo',JSON.stringify(info))
        } else {
            setStorage('userInfo',JSON.stringify(info))
        }
       
    },
    setLoginToken: (info)=>{
        if(isOld){
            window.localStorage.setItem('loginToken',info)
        } else {
            setStorage('loginToken',JSON.stringify(info))
        }
        
    },
    setTradeNo:  (info)=>{
        if(isOld){
            window.localStorage.setItem('tradeNo',info)
        } else {
            setStorage('tradeNo',JSON.stringify(info))
        }
       
    },
    setOrderInfo: (info)=>{
        if(isOld){
            window.localStorage.setItem('orderInfo',JSON.stringify(info))
        } else {
            setStorage('orderInfo',JSON.stringify(info))
        }
       
    },
    setGoodsParams: (info)=>{
        if(isOld){
            window.localStorage.setItem('goodsParams',JSON.stringify(info))
        } else {
            setStorage('goodsParams',JSON.stringify(info))
        }
       
    },
    setPayDetailBack:  (info)=>{
        if(isOld){
            window.localStorage.setItem('payDetailBack',info)
        } else {
            setStorage('payDetailBack',JSON.stringify(info))
        }
       
    },


    

}