import axios from "./axios";
import { Response } from "./interface";

export default {
  // 文章详情
  goodsDetailApi: (params) => {
    return axios.post("supplier/product/detail", params, {
      headers: { packagePlatform: "Web" },
    });
  },
  // 发送验证码
  sendCodeApi: (params) => {
    return axios.post("user/index/sendCode", params);
  },
  // 登录
  loginInApi: (params) => {
    return axios.post("user/index/login", params);
  },
  // 结算
  orderSaveApi: (params) => {
    return axios.post("supplier/product/settlement", params);
  },
  // 支付
  // payApi:(params)=>{ return axios.post('supplier/payment/create',params) },
  payApi: (params) => {
    return axios.post("supplier/payment/create", params, {
      headers: { packagePlatform: "Wap" },
    });
  },
  // 支付状态
  payStatusApi: (params) => {
    return axios.post("order/payment/query", params);
  },
  // 订单详情
  orderDetailApi: (params) => {
    return axios.post("order/order/getDetails", params);
  },
  // 帮助列表
  gethelpList: (params = {}): Promise<Response> => {
    return axios.post("supplier/help/index", params);
  },
  // 帮助详情
  gethelpDetail: (params): Promise<Response> => {
    return axios.post("supplier/help/detail", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
};
